import React from "react";
import ReactDOM from "react-dom";
import MainSite from "./MainSite";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { Config } from './config';
import { getUser, setupApiOptions } from './service/FetchUtil';
import './i18n';
import { launchAppPanel, resolveDomain } from "./service/Util";

setupApiOptions({ credentials: 'include', baseUrl: Config.apiHost.replace("${domain}", resolveDomain()), contentType : 'text/plain' });

if (window.location.pathname==="/applink"){
  const user = getUser();
  if (user){
    const qs = new URLSearchParams(window.location.search);
    const email = qs.get("e");
    if (user.email!=email){
      window.location.href = "/login?e="+email;
    } else {
      const tn = qs.get("tn");
      const data = user.data.filter(t=>t.tenantId==tn);
      if (data && data.length>0){
        const linkCode = qs.get("lnk");
        var path = undefined;
        var pathQs = undefined;
        if (linkCode==="ytkony"){
          path = "/ortak/yetkitalepleri";
          if (qs.get("usr"))
            pathQs = "kullaniciId="+qs.get("usr");
        } else if (linkCode==="blgony"){
            path = "/ortak/onaytalepleri/" + qs.get("mdl");
          if (qs.get("tkn"))
            pathQs = "tkn="+qs.get("tkn");
        }
        const root = document.getElementById("root");
        root.innerHTML = "<p><code><big>"+data[0].label+" açılıyor...</big></code></p>";
        launchAppPanel(data[0].tenantId, data[0].api, path, pathQs);
      }
    }
  }
} else {
  ReactDOM.render(<BrowserRouter>
    <MainSite config={Config}/>
  </BrowserRouter>, document.getElementById("root"));
  
  serviceWorker.unregister();
}