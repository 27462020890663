import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { withRouter } from "react-router";
import {
    Row,
    Col, 
    Modal,
    ModalBody,
    Label,
    FormGroup,
    Input,
    Card,
    CardBody,
    Form,
    FormFeedback,
} from "reactstrap";

import sha256 from 'crypto-js/sha256';
import encBase64 from "crypto-js/enc-base64";

import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { goauth, login } from '../../service/backend';
import { getUser, logoutUser, setUser, showError } from '../../service/FetchUtil';

//Import Icons
import FeatherIcon from "feather-icons-react";
import Button from "../custom/button/Button";
import BaseViewComponent from "../custom/view/BaseViewComponent";

import ForgotPassword from "../../components/Layout/ForgotPassword";

class Login extends BaseViewComponent {

    constructor(props) {
        super(props);
        const url = new URLSearchParams(window.location.search);
        this.state = {
            isOpen: false,
            forgotpasswordModal: false,
            gotoProfile: url.get("profile") == "true",
            sessionExpired: url.get("sessionex") == "true",
            launchApp: url.get("app"),
            model: {
                email: url.get("em")
            }
        };
        this._dirty_ = false;
        
        Object.defineProperty(this, '_dirty_', {
            get: function() { return false; },
            set: function(val) {}
        });
        
        if (this.state.sessionExpired) {
            const current = getUser();
            if (current && current.email){
                logoutUser();
                this.state.model.email = current.email;
            }
        }
    }

    
    togglePasswordModal = () => {
        this.setState((prevState) => ({
            forgotpasswordModal: !prevState.forgotpasswordModal
        }));
    };

    render() {
        return <div className="cover-user-img d-flex align-items-center">
            <Row>
                <Col xs={12}>
                    <Card
                        className="login-page border-0"
                        style={{ zIndex: "1" }}>
                        <CardBody className="p-0">
                            <h4 className="card-title text-center">{this._tr("Üye Girişi")}</h4>
                            <Form className="llogin-form mt-4">
                                <Row>
                                    <Col lg={12}>
                                        <FormGroup className="position-relative">
                                            <Label htmlFor="email">
                                                E-Posta{" "}
                                                <span className="text-danger">*</span>
                                            </Label>
                                            <div className="position-relative">
                                                <i>
                                                    <FeatherIcon
                                                        icon="user"
                                                        className="fea icon-sm icons"
                                                    />
                                                </i>
                                            </div>
                                            <Input type="text" id="email" name="email" testid="login_txtEmail" value={this.state.model.email} onChange={this._handleTextChange} required
                                                placeholder="E-Posta adresiniz" ref={r => this.tbEmail = r} className="form-control pl-5"
                                                autoFocus={this.state.model.email == undefined || this.state.model.email == ""}
                                                invalid={this.state.model.email == undefined || this.state.model.email.trim().length < 5} />
                                            <FormFeedback>En az 5 karakter olmalıdır</FormFeedback>
                                        </FormGroup>
                                    </Col>

                                    <Col lg={12}>
                                        <FormGroup className="position-relative">
                                            <Label htmlFor="password">
                                                Şifre
                                                <span className="text-danger">*</span>
                                            </Label>
                                            <div className="position-relative">
                                                <i>
                                                    <FeatherIcon
                                                        icon="lock"
                                                        className="fea icon-sm icons"
                                                    />
                                                </i>
                                            </div>
                                            <Input type="password" id="password" name="password" testid="login_txtPassword" value={this.state.model.password} onChange={this._handleTextChange} onKeyDown={this.handleLoginPassword} required
                                                placeholder="Şifreniz" ref={r => this.tbSifre = r} className="form-control pl-5"
                                                autoFocus={this.state.model.email != undefined && this.state.model.email != ""}
                                                invalid={this.state.model.password == undefined || this.state.model.password.trim().length < 6} />
                                            <FormFeedback>En az 6 karakter olmalıdır</FormFeedback>
                                        </FormGroup>
                                    </Col>

                                    <Col lg="12">
                                        <div className="d-flex justify-content-between">
                                            <FormGroup>
                                                <div className="custom-control custom-checkbox">
                                                    <Input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id="remember"
                                                        name="remember"
                                                        value={this.state.model.remember}
                                                        onChange={this._handleCheckChange}
                                                    />
                                                    <Label
                                                        className="custom-control-label"
                                                        htmlFor="remember"
                                                    >
                                                        Beni hatırla
                                                    </Label>
                                                </div>
                                            </FormGroup>
                                            <p className="float-right forgot-pass">
                                                <Link
                                                    to="#"
                                                    className="text-dark font-weight-bold"
                                                    onClick={this.togglePasswordModal}
                                                >
                                                    Şifremi unuttum!
                                                </Link>
                                            </p>
                                        </div>
                                    </Col>
                                    <Col lg={12} className="mb-0">
                                        <Button id="girisBtn" testid="login_girisBtn" color="primary" block showBusy onClick={this.handleLogin}>
                                            Giriş
                                        </Button>
                                    </Col>

                                    <Col lg={12} className="mt-4 text-center">
                                        <h6>yada yetkilendirme servisi</h6>
                                        <ul className="list-unstyled social-icon mb-0 mt-3">
                                            <li className="list-inline-item">
                                                <GoogleOAuthProvider clientId={this.props.config.googleOAuthClientId} scope="https://www.googleapis.com/auth/userinfo.emailsss https://www.googleapis.com/auth/userinfo.profile">
                                                    <GoogleLogin size="medium" useOneTap={false}
                                                    onSuccess={this.googleSuccess} onFailure={this.googleError} onRequest={this.googleLoading}/>
                                                </GoogleOAuthProvider>
                                            </li>
                                        </ul>
                                    </Col>

                                    <Col className="text-center">
                                        <p className="mb-0 mt-3">
                                            <Link
                                                to="#"
                                                className="text-dark font-weight-bold"
                                                onClick={this.props.onSignUp}
                                                testid="linkSignup"
                                            >
                                                <small className="text-dark mr-2">
                                                    Henüz üye değil misiniz?
                                                </small>
                                            </Link>
                                        </p>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Modal
                    isOpen={this.state.forgotpasswordModal}
                    tabIndex="-1"
                    centered
                    contentClassName="rounded shadow-lg border-0 overflow-hidden"
                    toggle={this.togglePasswordModal}
                >
                    <ModalBody className="py-5">
                        <ForgotPassword />
                    </ModalBody>
                </Modal>
        </div>;
    }

    handleLoginPassword = (event, args, resetBusy) => {
        if (!this.tbEmail.props.invalid && !this.tbSifre.props.invalid) {
            if (event != undefined && event.keyCode != undefined && event.keyCode === 13) {
                //console.log(event.keyCode);
                document.getElementById("girisBtn").click();
            }
        }
    }

    performRedirect = (event) => {
        const { gotoProfile, launchApp } = this.state;
        if (launchApp != undefined) {
            window.location.href = "/app";
        } else if (gotoProfile) {
            window.location.href = "/profile";
        } else
            window.location.href = "/";
    }

    handleLogin = (event, args, resetBusy) => {
        const { model } = this.state;
        const that = this;
        if (model.email && model.password) {
            login({ e: model.email, p: sha256(model.password).toString(encBase64) }, (resp) => {
                setUser(resp, model.remember);
                that.performRedirect(event);
            }, null, resetBusy);
        } else if (resetBusy)
            resetBusy();
    }

    googleSuccess = response => {
        const that = this;
        goauth(response.credential, (resp) => {
            if (resp) {
                setUser(resp, true);
                that.performRedirect(null);
            }
        });
    }

    googleError = response => {
        console.error(response) // eslint-disable-line
    }

    googleLoading = () => {
        console.log('loading') // eslint-disable-line
    }

    googleLogout = () => {
        console.log('logout') // eslint-disable-line
    }

    componentDidMount() {
        const { sessionExpired, model } = this.state;
        if (!sessionExpired && model.email) {
            const current = getUser();
            if (current && current.email == model.email) {
                this.performRedirect(null);
                return;
            }
        }
        if (model.email && model.email.trim().length > 5) {
            this.tbSifre.focus();
        } else
            this.tbEmail.focus();
    }

}
//export default withTranslation()(Login);
export default withRouter(withTranslation()(Login));