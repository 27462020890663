import React, { PureComponent } from 'react';
import { kvdl } from '../../../service/FetchUtil';

export default class BaseViewComponent extends PureComponent {

    constructor(props) {
        super(props);
        this._tr = this.props.t;
        this._dirty_ = false;
        
        const that = this;
        const { history } = this.props;
        
        if (history) {
            this._unblock = history.block((location, action) => {
                if (that._dirty_) {
                    const confirmed = process.env.NODE_ENV === 'production'
                        ? window.confirm("Kaydedilmemiş veriler var, devam etmek istiyor musunuz?")
                        : window.confirm(this.constructor.name + " formunda kaydedilmemiş veriler var, devam etmek istiyor musunuz?");
                    
                    if (confirmed) {
                        that._dirty_ = false;
                    }
                    
                    return confirmed;
                }
                return true;
            });
        }
        
        this._onBeforeUnload = this._onBeforeUnload.bind(this);
        window.addEventListener('beforeunload', this._onBeforeUnload);
    }
    
    _onBeforeUnload(event) {
        if (this._dirty_) {
            const message = "Kaydedilmemiş veriler var, devam etmek istiyor musunuz?";
            event.returnValue = message;
            return message;
        }
    }

    componentDidMount() {
        this.refreshKVDL(this.kvdl);
    }

    refreshKVDL(kvdls) {
        if (this._components && kvdls && kvdls.length > 0) {
            //{component:vm.currencyCode,code:OrtakCombo.Currencies}
            kvdl(kvdls, (data) => {
                const d = Object.assign(this.state.kvdl || {}, data);
                this.setState({ kvdl: d });
            }, (err) => {
                console.debug(err);
            });
        }
    }

    _handleTextChange = (event) => {
        const fieldName = (event.target||event.currentTarget).name;
        const fieldVal = (event.target||event.currentTarget).value;
        if (fieldName.indexOf(".") > -1) {
            this._setValueByPath(this.state, fieldName, fieldVal);
            const plist = fieldName.split('.');
            this.setState({ [plist[0]]: { ...this.state[plist[0]] }}, () => {
                this._afterStateChanged(fieldName);
                this._dirty_ = true;
            });
        } else this.setState({ model: { ...this.state.model, [fieldName]: fieldVal } }, () => {
            this._afterStateChanged(fieldName);
            this._dirty_ = true;
        });
    }

    _handleNumberChange = (event) => {
        const fieldName = event.target.name;
        const fieldVal = event.target.value*1;
        if (fieldName.indexOf(".") > -1) {
            this._setValueByPath(this.state, fieldName, fieldVal);
            const plist = fieldName.split('.');
            this.setState({ [plist[0]]: { ...this.state[plist[0]] }}, () => {
                this._afterStateChanged(fieldName);
                this._dirty_ = true;
            });
        } else this.setState({ model: { ...this.state.model, [fieldName]: fieldVal } }, () => {
            this._afterStateChanged(fieldName);
            this._dirty_ = true;
        });
    }

    _handleSelectChange = (event) => {
        const fieldName = event.target.name;
        const fieldVal = event.target.value;
        const fieldLabel = event.target.getAttribute("nameLabel");
        const labelVal = fieldLabel ? event.target.options[event.target.selectedIndex].innerHTML : null;
        if (fieldName.indexOf(".") > -1) {
            this._setValueByPath(this.state, fieldName, fieldVal);
            if (fieldLabel)
                this._setValueByPath(this.state, fieldLabel, labelVal);
            const plist = fieldName.split('.');
            this.setState({ [plist[0]]: { ...this.state[plist[0]] } }, () => {
                this._afterStateChanged(fieldName);
                this._dirty_ = true;
            });
        } else if (fieldLabel) {
            this.setState({ model: { ...this.state.model, [fieldName]: fieldVal, [fieldLabel]: labelVal } }, () => {
                this._afterStateChanged(fieldName);
                this._dirty_ = true;
            });
        }
        else {
            this.setState({ model: { ...this.state.model, [fieldName]: fieldVal } }, () => {
                this._afterStateChanged(fieldName);
                this._dirty_ = true;
            });
        }
    }

    _handleValueChanged = (object, name, val, name2, val2, callback) => {
        object = object || this.state.model;
        var firstObjectName = null;
        var secondObjectName = null;
        if (name.indexOf(".") > -1)
            firstObjectName = name.split('.')[0];
        this._setValueByPath(object, name, val);
        if (name2 != undefined) {
            if (name2.indexOf(".") > -1)
                secondObjectName = name2.split('.')[0];
            this._setValueByPath(object, name2, val2);
        }
        if (firstObjectName && secondObjectName && firstObjectName != secondObjectName) {
            this.setState({ [firstObjectName]: { ...this.state[firstObjectName] }, [secondObjectName]: { ...this.state[secondObjectName] }, _dirty_: true }, callback);
        } else {
            if (firstObjectName)
                this.setState({ [firstObjectName]: { ...this.state[firstObjectName] }, _dirty_: true }, callback);
            else if (secondObjectName)
                this.setState({ [secondObjectName]: { ...this.state[secondObjectName] }, _dirty_: true }, callback);
            else
                this.setState({ _dirty_: true }, callback);
        }
    }

    _setValueByPath = (obj, path, value) => {
        var schema = obj;  // a moving reference to internal objects within obj
        const pList = path.split('.');
        const len = pList.length;
        for (var i = 0; i < len - 1; i++) {
            var elem = pList[i];
            if (!schema[elem]) schema[elem] = {}
            schema = schema[elem];
        }

        schema[pList[len - 1]] = value;
    }

    _handleKeyValueChange = (keyName,keyVal,valueName,valueVal) => {
        this.setState({ model: { ...this.state.model, [keyName]: keyVal, [valueName]: valueVal } }, () => {
            this._afterStateChanged(keyName)
        });
    }

    _handlePropChange = (keyName, keyVal) => {
        this.setState({ model: { ...this.state.model, [keyName]: keyVal } }, () => {
            this._afterStateChanged(keyName)
        });
    }

    _handleDateChange = (name, mmnt) => {
        this.setState({ model: { ...this.state.model, [name]: mmnt } }, () => {
            this._afterStateChanged(name)
        });
    }

    _handleCheckChange = (event) => {
        const fieldName = event.target.name;
        const fieldVal = event.target.checked;
        if (fieldName.indexOf(".") > -1) {
            this._setValueByPath(this.state, fieldName, fieldVal);
            const plist = fieldName.split('.');
            this.setState({ [plist[0]]: { ...this.state[plist[0]] } }, () => {
                this._afterStateChanged(fieldName);
                this._dirty_ = true;
            });
        } else this.setState({ model: { ...this.state.model, [fieldName]: fieldVal } }, () => {
            this._afterStateChanged(fieldName);
            this._dirty_ = true;
        });
    }

    _afterStateChanged(fieldName) {
    }

    goto = (path, args) => {
        this._dirty_ = false;
        this.props.history.push({
            pathname: path,
            state: args
        });
    }

    goBack = () => {
        this._dirty_ = false;
        this.props.history.goBack();
    }

    isBack = () => {
        return this.props.history.action == "POP";
    }

    componentWillUnmount() {
        if (this._unblock) {
            this._unblock();
            this._unblock = null;
        }
        window.removeEventListener('beforeunload', this._onBeforeUnload);
        this._dirty_ = false;
    }
}